import React, { useEffect, useRef, useState } from 'react';
import ReactDOM from 'react-dom';

const IframeComponent = ({ children }) => {
  const iframeRef = useRef(null);
  const [iframeDocument, setIframeDocument] = useState(null);

  useEffect(() => {
    if (iframeRef.current) {
      const doc = iframeRef.current.contentDocument;
      if (doc) {
        setIframeDocument(doc);

        // Inyectar las hojas de estilo
        const styleTags = Array.from(document.head.getElementsByTagName("style"));
        // exclude first style tag
        styleTags.shift();
        styleTags.forEach(tag => {
          const newTag = doc.createElement("style");
          newTag.innerHTML = tag.innerHTML;
          doc.head.appendChild(newTag);
        });

        const linkTags = Array.from(document.head.getElementsByTagName("link"));
        linkTags.forEach(tag => {
          if (tag.rel === "stylesheet") {
            const newTag = doc.createElement("link");
            newTag.rel = "stylesheet";
            newTag.href = tag.href;
            doc.head.appendChild(newTag);
          }
        });
      }
    }
  }, []);

  return (
    <iframe
      ref={iframeRef}
      style={{ width: '2000px',
              height: '1080px',
              border: 'none',
              transform: 'scale(0.7)',
              transformOrigin: 'top left' }}
      title="Print Frame"
    >
      {iframeDocument && ReactDOM.createPortal(children, iframeDocument.body)}
    </iframe>
  );
};

export default IframeComponent;
