export const HavasGroupImg = () => {
    return (
    <svg width="80" height="15" viewBox="0 0 129 27" fill="none" xmlns="http://www.w3.org/2000/svg">
<g id="Group">
<path id="Vector" d="M20.0391 8.10891L4.03032 15.5739V0.495117H0V26.3932H4.03032V19.7788L20.0391 12.3138L20.0391 26.3932H24.0694V0.495117H20.0391L20.0391 8.10891Z" fill="#D32D1F"/>
<path id="Vector_2" d="M108.712 7.80356C108.845 5.59393 112.424 3.93283 116.215 3.93283C120.08 3.93283 123.725 5.65787 123.725 7.93104H127.916C127.916 3.12526 122.632 0 116.215 0C109.798 0 104.515 3.12526 104.515 7.93104V10.1858L124.059 19.2996C123.702 21.4012 120.098 22.9556 116.431 22.9556C112.566 22.9556 108.769 21.2306 108.769 18.9574H104.579C104.579 23.7632 110.014 26.8885 116.431 26.8885C122.847 26.8885 128.282 23.7632 128.282 18.9574V16.9294L108.712 7.80356Z" fill="#D32D1F"/>
<path id="Vector_3" d="M75.115 0.495117L65.1811 23.8975L55.2472 0.495117H50.8689L61.8622 26.3932H68.5L79.4933 0.495117H75.115Z" fill="#D32D1F"/>
<path id="Vector_4" d="M55.1421 26.3932L44.1488 0.495117H37.511L26.5177 26.3932H30.896L31.7887 24.29L46.8832 17.2514L50.7637 26.3932H55.1421ZM40.8299 2.99083L45.3932 13.7413L34.0141 19.0474L40.8299 2.99083Z" fill="#D32D1F"/>
<path id="Vector_5" d="M103.844 26.3932L92.8512 0.495117H86.2133L75.2201 26.3932H79.5984L80.4911 24.29L95.5856 17.2514L99.4661 26.3932H103.844ZM89.5323 2.99083L94.0956 13.7413L82.7165 19.0474L89.5323 2.99083Z" fill="#D32D1F"/>
</g>
</svg>
    
    )
    }