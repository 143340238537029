import PropTypes from "prop-types";
import React from "react";
import styles from "./cardNotAppl.module.css";
import { VectorImg } from "../img/VectorImg";

export const CardNotAppl = ({ title, description }) => {
  // Function to extract URL from the description
  const extractUrl = (description) => {
    const regex = /\[.*?\]\((.*?)\)/; // Matches markdown link syntax
    const matches = description.match(regex);
    return matches ? matches[1] : null;
  };

  const extractedUrl = extractUrl(description);
  
  return (
    <div className={styles.frame}>
      <div className={styles['frame-wrapper']}>
        <div className={styles.div}>
          <p className={styles['elements-with-an']}>{title}</p>
          {/* Wrap VectorImg in a div with an onClick event if a URL is found */}
          {extractedUrl ? (
            <a href={extractedUrl} target="_blank" rel="noopener noreferrer">
              <VectorImg className="url" alt="Url" src={extractedUrl} />
            </a>
          ) : (
            <VectorImg className="url" alt="Url" src={extractedUrl} />
          )}
        </div>
      </div>
    </div>
  );
};

CardNotAppl.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
};
