// src/pages/AccessibilityData.js
import React, { useEffect, useState } from 'react';
import {IntroComponent} from '../components/IntroComponent';
import {IntroTwoComponent} from '../components/IntroTwoComponent';
import {IntroThreeComponent} from '../components/IntroThreeComponent';
import {IntroFourComponent} from '../components/IntroFourComponent';
import {EndComponent} from '../components/EndComponent';
import PrintComponent from './PrintComponent';
import {GenDataComponent} from '../components/GenDataComponent';
import {CardsDataComponent} from '../components/CardsDataComponent';

const AccessibilityData = ({ jsonFiles, configData }) => {
  const [jsonDataArray, setJsonDataArray] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      let tempData = [];

      for (const webpage of configData.data[0].webpages) {
        for (const route of webpage.routes) {
          const file = jsonFiles.find(file => file.path.endsWith(route.path.split('/').pop()));
          if (file) {
            const jsonData = file.content;
            jsonData.baseUrl = configData.data[0].url;
            jsonData.name = configData.data[0].name;
            jsonData.requestedUrl = `${configData.data[0].url}${route.path}`;
            jsonData.webPath = route.webPath;

            const { error, passed, manual, notAppl } = jsonData.sortedAudits;
            jsonData.errorArray = error;
            jsonData.passedArray = passed;
            jsonData.manualArray = manual;
            jsonData.notApplArray = notAppl;

            tempData.push(jsonData);
          }
        }
      }

      setJsonDataArray(tempData);
    };

    if (configData) {
      fetchData().catch(console.error);
    }
  }, [jsonFiles, configData]);

  return (
    <PrintComponent>
      <IntroComponent />
      <IntroTwoComponent />
      <IntroThreeComponent />
      <IntroFourComponent />
      {jsonDataArray.map((data, index) => (
        <React.Fragment key={`fragment-${index}`}>
          <GenDataComponent
            project={data.name}
            baseURL={data.baseUrl}
            score={data.accessibilityScore}
            path={data.webPath}
            errorNum={data.errorArray.length}
            passedNum={data.passedArray.length}
            notApplNum={data.notApplArray.length}
            manualNum={data.manualArray.length}
            manualArray={data.manualArray}
          />
          <CardsDataComponent status="PASSED" items={data.passedArray} {...data} />
          <CardsDataComponent status="ERROR" items={data.errorArray} {...data} />
          <CardsDataComponent status="NOT" items={data.notApplArray} {...data} />
        </React.Fragment>
      ))}
      <EndComponent />
    </PrintComponent>
  );
};

export default AccessibilityData;