export const TwoCirclesImg = () => {
    return (
<svg xmlns="http://www.w3.org/2000/svg" width="291" height="290" viewBox="0 0 291 290" fill="none">
  <path d="M289.201 1.29883H1.56008L145.275 144.727C112.48 111.884 112.515 58.7256 145.381 25.9253C178.282 -6.91 231.625 -6.91 264.526 25.9253C280.959 42.326 289.185 63.8166 289.201 85.3123V1.29883Z" fill="#D32D1F"/>
  <path d="M289.201 85.4456C289.185 106.941 280.959 128.432 264.526 144.833C231.66 177.633 178.397 177.668 145.487 144.939L289.201 288.366V85.4456Z" fill="#D32D1F"/>
  <path d="M26.2358 263.74C59.1368 296.575 112.48 296.575 145.381 263.74C163.888 245.27 171.984 220.345 169.671 196.227C167.872 177.468 159.775 159.198 145.381 144.833C126.874 126.363 101.899 118.282 77.7329 120.591C58.9369 122.386 40.63 130.467 26.2358 144.832C9.80006 161.235 1.57481 182.729 1.56008 204.228V204.344C1.57481 225.843 9.80005 247.337 26.2358 263.74Z" fill="#D32D1F"/>
  <path d="M289.201 1.29883H1.56008L145.275 144.727C112.48 111.884 112.515 58.7256 145.381 25.9253C178.282 -6.91 231.625 -6.91 264.526 25.9253C280.959 42.326 289.185 63.8166 289.201 85.3123V1.29883Z" stroke="black" strokeWidth="2.29654" strokeLinecap="round" strokeLinejoin="round"/>
  <path d="M289.201 85.4456C289.185 106.941 280.959 128.432 264.526 144.833C231.66 177.633 178.397 177.668 145.487 144.939L289.201 288.366V85.4456Z" stroke="black" strokeWidth="2.29654" strokeLinecap="round" strokeLinejoin="round"/>
  <path d="M26.2358 263.74C59.1368 296.575 112.48 296.575 145.381 263.74C163.888 245.27 171.984 220.345 169.671 196.227C167.872 177.468 159.775 159.198 145.381 144.833C126.874 126.363 101.899 118.282 77.7329 120.591C58.9369 122.386 40.63 130.467 26.2358 144.832C9.80006 161.235 1.57481 182.729 1.56008 204.228V204.344C1.57481 225.843 9.80005 247.337 26.2358 263.74Z" stroke="black" strokeWidth="2.29654" strokeLinecap="round" strokeLinejoin="round"/>
</svg>
)
}