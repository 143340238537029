import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { collection, addDoc, serverTimestamp, updateDoc, doc } from 'firebase/firestore';
import { db, storage } from '../firebase';
import { ref, uploadBytes, getDownloadURL } from 'firebase/storage';
import UploadComponent from '../components/UploadComponent';
import ConfigForm from '../components/ConfigForm';

const CreateReportComponent = () => {
  const [jsonFiles, setJsonFiles] = useState([]);
  const [message, setMessage] = useState('');
  const [messageType, setMessageType] = useState('');
  const navigate = useNavigate();

  const handleUpload = (files) => {
    setJsonFiles(files);
  };

  const handleSubmit = async (data) => {
    if (jsonFiles.length === 0) {
      setMessage('Please upload files before generating the report.');
      setMessageType('error');
      return;
    }

    const config = {
      name: data.name || '',
      url: data.url || '',
      scope: data.scope || '',
      timestamp: serverTimestamp()
    };

    try {
      // Crear el documento en Firestore
      const docRef = await addDoc(collection(db, 'accessibility-projects'), { config });
      const projectId = docRef.id;

      const updatedWebpages = [];

      // Subir cada archivo a Firebase Storage
      for (const file of jsonFiles) {
        const path = `projects/${projectId}/${file.name}`;
        const storageRef = ref(storage, path);
        await uploadBytes(storageRef, file.content);
        const downloadURL = await getDownloadURL(storageRef);

        updatedWebpages.push({
          name: file.name,
          path,
          webPath: file.webPath,
          url: downloadURL
        });
      }

      // Actualizar el documento en Firestore con las URLs de los archivos
      await updateDoc(doc(db, 'accessibility-projects', projectId), {
        config: { ...config, webpages: updatedWebpages }
      });

      setMessage('Data successfully sent to Firebase!');
      setMessageType('success');
    } catch (e) {
      console.error("Error adding document: ", e);
      setMessage('Error sending data to Firebase.');
      setMessageType('error');
    }
  };

  return (
    <div>
      <UploadComponent onUpload={handleUpload} />
      <ConfigForm onSubmit={handleSubmit} isDisabled={jsonFiles.length === 0} />
      {message && <p className={messageType}>{message}</p>}
      <button onClick={() => navigate('/list-projects')}>Go to Project List</button>
    </div>
  );
};

export default CreateReportComponent;

