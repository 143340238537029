import React from 'react';

export const EllipseScore = ({ score, color }) => {
  const radius = 41.4496;
  const circumference = 2 * Math.PI * radius;
  const offset = circumference - (score / 100) * circumference;

  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="85" height="85" viewBox="0 0 95 95" fill="none">
      <circle cx="47.038" cy="47.3005" r={radius} transform="rotate(-90 47.038 47.3005)" stroke="white" strokeWidth="11.177" />
      <circle
        cx="47.038"
        cy="47.3005"
        r={radius}
        transform="rotate(-90 47.038 47.3005)"
        stroke={color}
        strokeWidth="11.177"
        strokeDasharray={circumference}
        strokeDashoffset={offset}
        strokeLinecap="round"
        fill="none"
      />
      <circle cx="47.0377" cy="47.3005" r="35.8248" transform="rotate(-90 47.0377 47.3005)" fill="#F8F8F8" />
    </svg>
  );
};
