export const HavasGroupBigImg = () => {
    return (
<svg xmlns="http://www.w3.org/2000/svg" width="223" height="48" viewBox="0 0 223 48" fill="none">
  <path d="M34.8097 14.5858L7.00102 27.5532V1.35999H0V46.3472H7.00102V34.8574L34.8097 21.8901L34.8097 46.3472H41.8107V1.35999H34.8097L34.8097 14.5858Z" fill="#D32D1F"/>
  <path d="M188.842 14.0555C189.074 10.2172 195.29 7.33168 201.876 7.33168C208.589 7.33168 214.922 10.3282 214.922 14.2769H222.201C222.201 5.92885 213.023 0.5 201.876 0.5C190.73 0.5 181.552 5.92885 181.552 14.2769V18.1936L215.502 34.0252C214.881 37.6758 208.622 40.376 202.25 40.376C195.537 40.376 188.942 37.3794 188.942 33.4307H181.663C181.663 41.7788 191.104 47.2076 202.25 47.2076C213.397 47.2076 222.838 41.7788 222.838 33.4307V29.9079L188.842 14.0555Z" fill="#D32D1F"/>
  <path d="M130.481 1.35999L113.225 42.012L95.9693 1.35999H88.3638L107.46 46.3472H118.991L138.087 1.35999H130.481Z" fill="#D32D1F"/>
  <path d="M95.7866 46.3472L76.6903 1.35999H65.1598L46.0635 46.3472H53.669L55.2198 42.6939L81.4402 30.4671L88.181 46.3472H95.7866ZM70.9251 5.69527L78.852 24.3697L59.0855 33.587L70.9251 5.69527Z" fill="#D32D1F"/>
  <path d="M180.387 46.3472L161.291 1.35999H149.76L130.664 46.3472H138.27L139.82 42.6939L166.041 30.4671L172.782 46.3472H180.387ZM155.526 5.69527L163.453 24.3697L143.686 33.587L155.526 5.69527Z" fill="#D32D1F"/>
</svg>
    
    )
    }