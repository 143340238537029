export const HourglassBigImg = ({width, height}) => {
    return (
<svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 285 285" fill="none">
<path d="M142.571 142.649L2.22081 2.29883L282.922 2.29884L142.571 142.649Z" fill="#D32D1F"/>
<path d="M142.571 142.649L2.22081 2.29883L282.922 2.29884L142.571 142.649Z" fill="#D32D1F"/>
<path d="M142.571 142.649L282.921 283H2.22046L142.571 142.649Z" fill="#D32D1F"/>
<path d="M142.571 142.649L2.22081 2.29883L282.922 2.29884L142.571 142.649ZM142.571 142.649L282.921 283H2.22046L142.571 142.649Z" stroke="black" strokeWidth="3.44481" strokeLinejoin="round"/>
</svg>


)
}