import React, { useState, useCallback } from 'react';
import { useDropzone } from 'react-dropzone';
import JSZip from 'jszip';
import './createReport.css'; 

const UploadComponent = ({ onUpload }) => {
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState('');
  const [messageType, setMessageType] = useState('');

  const extractNameFromPath = (path) => {
    const parts = path.split('/').filter(part => part);

    if (parts.length <= 2) {
      return 'Home Page';
    }

    let significantPart = '';

    for (let i = 0; i < parts.length; i++) {
      if (parts[i] === '#' || parts[i] === '!') {
        significantPart = parts[i + 1] || parts[i - 1];
        break;
      }
    }

    if (!significantPart) {
      significantPart = parts[parts.length - 1];
    }

    return significantPart.split('-').map(word => word.charAt(0).toUpperCase() + word.slice(1)).join(' ');
  };

  const onDrop = useCallback(async (acceptedFiles) => {
    setLoading(true);
    setMessage('');

    const jsonFiles = [];

    for (const file of acceptedFiles) {
      if (file.name.endsWith('.zip')) {
        const zip = new JSZip();
        try {
          const content = await zip.loadAsync(file);

          for (const relativePath in content.files) {
            if (relativePath.endsWith('.json')) {
              const fileContent = await content.files[relativePath].async('string');
              const parsedContent = JSON.parse(fileContent);
              const finalDisplayedUrl = parsedContent.finalDisplayedUrl;

              const webPath = new URL(finalDisplayedUrl).pathname + new URL(finalDisplayedUrl).hash;
              const name = extractNameFromPath(webPath);
              const description = "Desktop Data";

              jsonFiles.push({
                name,
                content: new Blob([fileContent], { type: 'application/json' }),
                webPath,
                description
              });
            }
          }
        } catch (error) {
          console.error("Error extracting zip file: ", error);
          setMessage('Failed to upload files.');
          setMessageType('error');
        }
      } else if (file.name.endsWith('.json')) {
        try {
          const fileContent = await file.text();
          const parsedContent = JSON.parse(fileContent);
          const finalDisplayedUrl = parsedContent.finalDisplayedUrl;

          const webPath = new URL(finalDisplayedUrl).pathname + new URL(finalDisplayedUrl).hash;
          const name = extractNameFromPath(webPath);
          const description = "Desktop Data";

          jsonFiles.push({
            name,
            content: new Blob([fileContent], { type: 'application/json' }),
            webPath,
            description
          });
        } catch (error) {
          console.error("Error reading JSON file: ", error);
          setMessage('Failed to parse JSON file.');
          setMessageType('error');
        }
      } else {
        setMessage('Unsupported file type.');
        setMessageType('error');
      }
    }

    setLoading(false);
    if (jsonFiles.length > 0) {
      setMessage('Files uploaded successfully.');
      setMessageType('success');
      onUpload(jsonFiles);
    }
  }, [onUpload]);

  const { getRootProps, getInputProps } = useDropzone({ onDrop });

  return (
    <div {...getRootProps()} style={{ border: '2px dashed #007bff', padding: '20px', textAlign: 'center' }}>
      <input {...getInputProps()} />
      <p>Drag & drop some files here, or click to select files</p>
      {loading && <p>Loading...</p>}
      {message && <p className={messageType}>{message}</p>}
    </div>
  );
};

export default UploadComponent;
