export const HourglassImg = () => {
    return (
<svg width="52.4" height="52.4" viewBox="0 0 55 55" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M27.1507 27.1977L0.949742 0.996704L53.3517 0.996707L27.1507 27.1977Z" fill="#D32D1F"/>
<path d="M27.1507 27.1977L0.949742 0.996704L53.3517 0.996707L27.1507 27.1977Z" fill="#D32D1F"/>
<path d="M27.1507 27.1977L53.3516 53.3987H0.949677L27.1507 27.1977Z" fill="#D32D1F"/>
<path d="M27.1507 27.1977L0.949742 0.996704L53.3517 0.996707L27.1507 27.1977ZM27.1507 27.1977L53.3516 53.3987H0.949677L27.1507 27.1977Z" stroke="black" strokeWidth="1.83892" strokeLinejoin="round"/>
<rect x="0.949677" y="0.94104" width="52.5168" height="52.5168" stroke="black" strokeWidth="1.83892"/>
</svg>


)
}