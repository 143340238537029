import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Home from './pages/Home';
import AccessibilityData from './pages/AccessibilityData';
import CreateReportComponent from './pages/CreateReportComponent';
import ListProjectsComponent from './pages/ListProjectsComponent';
import ProjectDetails from './components/ProjectDetails';

function App() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/create" element={<CreateReportComponent />} />
        <Route path="/data" element={<AccessibilityData />} />
        <Route path="/list-projects" element={<ListProjectsComponent />} />
        <Route path="/project/:projectId" element={<ProjectDetails />} />
      </Routes>
    </Router>
  );
}

export default App;