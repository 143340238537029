export const FourCirclesSquareImg = () => {
    return (
<svg xmlns="http://www.w3.org/2000/svg" width="66" height="66" viewBox="0 0 66 66" fill="none">
  <rect width="63" height="63" transform="translate(1.65063 1.05579)" fill="white"/>
  <rect x="1.6499" y="1.05603" width="63" height="63" fill="#D32D1F" stroke="black" strokeWidth="2" strokeLinejoin="round"/>
  <path d="M6.68872 16.5708C6.68872 10.7718 11.3897 6.0708 17.1887 6.0708C22.9877 6.0708 27.6887 10.7718 27.6887 16.5708C27.6887 22.3698 22.9877 27.0708 17.1887 27.0708C11.3897 27.0708 6.68872 22.3698 6.68872 16.5708Z" fill="white" stroke="black" strokeWidth="2" strokeLinejoin="round"/>
  <path d="M6.68872 48.5782C6.68872 42.7793 11.3897 38.0782 17.1887 38.0782C22.9877 38.0782 27.6887 42.7793 27.6887 48.5782C27.6887 54.3772 22.9877 59.0782 17.1887 59.0782C11.3897 59.0782 6.68872 54.3772 6.68872 48.5782Z" fill="white" stroke="black" strokeWidth="2" strokeLinejoin="round"/>
  <path d="M38.6968 16.5708C38.6968 10.7718 43.3978 6.0708 49.1968 6.0708C54.9958 6.0708 59.6968 10.7718 59.6968 16.5708C59.6968 22.3698 54.9958 27.0708 49.1968 27.0708C43.3978 27.0708 38.6968 22.3698 38.6968 16.5708Z" fill="white" stroke="black" strokeWidth="2" strokeLinejoin="round"/>
  <path d="M38.6968 48.5782C38.6968 42.7793 43.3978 38.0782 49.1968 38.0782C54.9958 38.0782 59.6968 42.7793 59.6968 48.5782C59.6968 54.3772 54.9958 59.0782 49.1968 59.0782C43.3978 59.0782 38.6968 54.3772 38.6968 48.5782Z" fill="white" stroke="black" strokeWidth="2" strokeLinejoin="round"/>
</svg>

)
}