export const SquareLoopsImg = () => {
    return (
<svg xmlns="http://www.w3.org/2000/svg" width="209" height="209" viewBox="0 0 209 209" fill="none">
  <path d="M1.60181 36.2895C1.60181 17.3798 16.9312 2.05042 35.8409 2.05042C54.7507 2.05042 70.0801 17.3798 70.0801 36.2895V70.5287H35.8409C16.9312 70.5287 1.60181 55.1993 1.60181 36.2895Z" fill="#D32D1F"/>
  <path d="M207.037 173.246C207.037 192.156 191.707 207.485 172.797 207.485C153.888 207.485 138.558 192.156 138.558 173.246L138.558 139.007H172.797C191.707 139.007 207.037 154.336 207.037 173.246Z" fill="#D32D1F"/>
  <path d="M172.797 2.05042C191.707 2.05042 207.037 17.3798 207.037 36.2895C207.037 55.1993 191.707 70.5287 172.797 70.5287H138.558V36.2895C138.558 17.3798 153.888 2.05041 172.797 2.05042Z" fill="#D32D1F"/>
  <path d="M35.8409 207.485C16.9312 207.485 1.6018 192.156 1.60181 173.246C1.60181 154.336 16.9312 139.007 35.8409 139.007H70.0801L70.0801 173.246C70.0801 192.156 54.7507 207.485 35.8409 207.485Z" fill="#D32D1F"/>
  <path d="M138.558 70.5287L138.558 139.007L70.0801 139.007L70.0801 70.5287H138.558Z" fill="#D32D1F"/>
  <path d="M70.0801 70.5287V36.2895C70.0801 17.3798 54.7507 2.05042 35.8409 2.05042C16.9312 2.05042 1.60181 17.3798 1.60181 36.2895C1.60181 55.1993 16.9312 70.5287 35.8409 70.5287H70.0801ZM70.0801 70.5287L70.0801 139.007M70.0801 70.5287H138.558M138.558 139.007L138.558 173.246C138.558 192.156 153.888 207.485 172.797 207.485C191.707 207.485 207.037 192.156 207.037 173.246C207.037 154.336 191.707 139.007 172.797 139.007H138.558ZM138.558 139.007L138.558 70.5287M138.558 139.007L70.0801 139.007M138.558 70.5287H172.797C191.707 70.5287 207.037 55.1993 207.037 36.2895C207.037 17.3798 191.707 2.05042 172.797 2.05042C153.888 2.05041 138.558 17.3798 138.558 36.2895V70.5287ZM70.0801 139.007H35.8409C16.9312 139.007 1.60181 154.336 1.60181 173.246C1.6018 192.156 16.9312 207.485 35.8409 207.485C54.7507 207.485 70.0801 192.156 70.0801 173.246L70.0801 139.007Z" stroke="black" strokeWidth="3" strokeLinejoin="round"/>
</svg>
    )
    }