import React, { useEffect, useState } from 'react';
import { collection, getDocs, deleteDoc, doc } from 'firebase/firestore';
import { db, storage } from '../firebase';
import { useNavigate } from 'react-router-dom';
import { ref, listAll, deleteObject } from 'firebase/storage';
// import { Menu, MenuButton, MenuItem, MenuItems } from '@headlessui/react'
// import { EllipsisVerticalIcon } from '@heroicons/react/20/solid'
const ListProjectsComponent = () => {
  const [projects, setProjects] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchProjects = async () => {
      try {
        const querySnapshot = await getDocs(
          collection(db, 'accessibility-projects')
        );
        const projectsData = querySnapshot.docs.map((doc) => {
          const data = doc.data();
          const config = data.config || {};
          return {
            id: doc.id,
            name: config.name || 'Unnamed',
            timestamp: config.timestamp || null,
            webpages: config.webpages || [],
          };
        });
        setProjects(projectsData);
      } catch (error) {
        console.error('Error fetching documents: ', error);
      }
    };

    fetchProjects();
  }, []);

  const handleDelete = async (projectId) => {
    try {
      const storageRef = ref(storage, `projects/${projectId}`);
      const allFiles = await listAll(storageRef);

      const deletePromises = allFiles.items.map((fileRef) =>
        deleteObject(fileRef)
      );
      await Promise.all(deletePromises);

      await deleteDoc(doc(db, 'accessibility-projects', projectId));
      setProjects((prevProjects) =>
        prevProjects.filter((project) => project.id !== projectId)
      );
    } catch (error) {
      console.error('Error deleting document: ', error);
    }
  };

  const handleNavigateToCreate = () => {
    navigate('/create');
  };

  const handleNavigateToDetails = (projectId) => {
    navigate(`/project/${projectId}`);
  };

  const formatTimestamp = (timestamp) => {
    if (!timestamp) return 'No Date';
    if (timestamp.toDate) {
      return new Date(timestamp.toDate()).toLocaleString();
    }
    return 'No Date';
  };

  return (
    <div>
      <h1>List of Uploaded Projects</h1>
      <button onClick={handleNavigateToCreate}>Create New Project</button>
      <ul>
        {projects.map((project) => (
          <li key={project.id}>
            <strong>Name:</strong> {project.name} <br />
            <strong>Date:</strong> {formatTimestamp(project.timestamp)} <br />
            {/* <div>
              {project.webpages.map((file, index) => (
                <div key={index}>
                  <button onClick={() => window.open(file.url, '_blank')}>
                    Download {file.name} JSON
                  </button>
                </div>
              ))}
            </div> */}
            <button onClick={() => handleNavigateToDetails(project.id)}>
              View PDF
            </button>
            <button onClick={() => handleDelete(project.id)}>Delete</button>
          </li>
        //   <li key={project.id} className="flex items-center justify-between gap-x-6 py-5">
        //   <div className="min-w-0">
        //     <div className="flex items-start gap-x-3">
        //       <p className="text-sm font-semibold leading-6 text-gray-900">{project.name}</p>
        //       <p
        //         className='mt-0.5 whitespace-nowrap rounded-md px-1.5 py-0.5 text-xs font-medium ring-1 ring-inset'>
        //         {project.status}
        //       </p>
        //     </div>
        //     <div className="mt-1 flex items-center gap-x-2 text-xs leading-5 text-gray-500">
        //       <p className="whitespace-nowrap">
        //         <time dateTime={project.timestamp}>{formatTimestamp(project.timestamp)}</time>
        //       </p>
        //       <svg viewBox="0 0 2 2" className="h-0.5 w-0.5 fill-current">
        //         <circle r={1} cx={1} cy={1} />
        //       </svg>
        //       <p className="truncate">Created by {project.createdBy}</p>
        //     </div>
        //   </div>
        //   <div className="flex flex-none items-center gap-x-4">
        //       <button className="hidden rounded-md bg-white px-2.5 py-1.5 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:block" onClick={() => handleNavigateToDetails(project.id)}>
        //       View project<span className="sr-only">, {project.name}</span>
        //       </button>
        //     <Menu as="div" className="relative flex-none">
        //       <MenuButton className="-m-2.5 block p-2.5 text-gray-500 hover:text-gray-900">
        //         <span className="sr-only">Open options</span>
        //         <EllipsisVerticalIcon aria-hidden="true" className="h-5 w-5" />
        //       </MenuButton>
        //       <MenuItems
        //         transition
        //         className="absolute right-0 z-10 mt-2 w-32 origin-top-right rounded-md bg-white py-2 shadow-lg ring-1 ring-gray-900/5 transition focus:outline-none data-[closed]:scale-95 data-[closed]:transform data-[closed]:opacity-0 data-[enter]:duration-100 data-[leave]:duration-75 data-[enter]:ease-out data-[leave]:ease-in"
        //       >
        //         <MenuItem>
        //           <button className="block px-3 py-1 text-sm leading-6 text-gray-900 data-[focus]:bg-gray-50" onClick={() => handleDelete(project.id)}>Delete<span className="sr-only">, {project.name}</span></button>
        //         </MenuItem>
        //       </MenuItems>
        //     </Menu>
        //   </div>
        // </li>
        ))}
      </ul>
    </div>
  );
};

export default ListProjectsComponent;
