import React, { useEffect, useState, useRef } from 'react';
import { useParams } from 'react-router-dom';
import { doc, getDoc } from 'firebase/firestore';
import { getStorage, ref, getMetadata } from "firebase/storage";
import { db } from '../firebase';
import ReactToPrint from 'react-to-print';
import { IntroComponent } from '../components/IntroComponent';
import { IntroTwoComponent } from '../components/IntroTwoComponent';
import { IntroThreeComponent } from '../components/IntroThreeComponent';
import { IntroFourComponent } from '../components/IntroFourComponent';
import { EndComponent } from '../components/EndComponent';
import { GenDataComponent } from '../components/GenDataComponent';
import { CardsDataComponent } from '../components/CardsDataComponent';
import IframeComponent from './IframeComponent';
import './createReport.css'; 

const ProjectDetails = () => {
  const { projectId } = useParams();
  const [jsonDataArray, setJsonDataArray] = useState([]);
  const [projectData, setProjectData] = useState(null);
  const [uploadDate, setUploadDate] = useState(null);
  const printRef = useRef();

  useEffect(() => {
    const fetchData = async () => {
      const docRef = doc(db, 'accessibility-projects', projectId);
      const docSnap = await getDoc(docRef);
      if (docSnap.exists()) {
        const project = docSnap.data().config;
        setProjectData(project);
        // remove blank spaces and substitute them with underscores from project.name and project.scope
        let projectName = project.name.replace(/\s/g, '_');
        let projectScope = project.scope.replace(/\s/g, '_');
        
        document.title = 'Okb_Interactive-' + projectName +'-'+ projectScope+'-Web_Accessibility_Analysis'; // Cambia el título del documento
        let tempData = [];
    
        for (const webpage of project.webpages) {
          try {
            const response = await fetch(webpage.url, { mode: 'cors' });
            if (!response.ok) {
              throw new Error(`HTTP error! status: ${response.status}`);
            }
            const jsonData = await response.json();
    
            jsonData.baseUrl = project.url;
            jsonData.name = project.name;
            jsonData.requestedUrl = `${project.url}${webpage.path}`;
            jsonData.webPath = webpage.webPath;
    
            const sortedAudits = jsonData.audits || {};
            const errorArray = [];
            const passedArray = [];
            const manualArray = [];
            const notApplArray = [];
            
            for (const auditKey in sortedAudits) {
              const audit = sortedAudits[auditKey];
              if (audit.score === 0) {
                errorArray.push(audit);
              } else if (audit.score === 1) {
                passedArray.push(audit);
              } else {
                if (audit.scoreDisplayMode === "notApplicable") {
                  notApplArray.push(audit);
                } else {
                  manualArray.push(audit);
                }
              }
            }
    
            jsonData.errorArray = errorArray;
            jsonData.passedArray = passedArray;
            jsonData.manualArray = manualArray;
            jsonData.notApplArray = notApplArray;
            jsonData.accessibilityScore = Math.round(jsonData.categories.accessibility.score * 100);
    
            const storage = getStorage();
            const storageRef = ref(storage, webpage.path);
            const metadata = await getMetadata(storageRef);
            setUploadDate(metadata.timeCreated);
    
            tempData.push(jsonData);
          } catch (error) {
            console.error("Failed to fetch JSON file:", error);
          }
        }
    
        setJsonDataArray(tempData);
      } else {
        console.error('No such document!');
      }
    };

    fetchData().catch(console.error);
  }, [projectId]);

  return (
    <div>
      {jsonDataArray.length > 0 && (
        <div className="flex justify-center items-center min-h-screen bg-gray-200">
          <ReactToPrint
            trigger={() => <button>Print/Download PDF</button>}
            content={() => printRef.current}
          />
          <IframeComponent>
          <div ref={printRef} className="print-container">
              <div className="page-break"><IntroComponent projectData={projectData} /></div>
              <div className="page-break"><IntroTwoComponent projectData={projectData} /></div>
              <div className="page-break"><IntroThreeComponent projectData={projectData} /></div>
              <div className="page-break"><IntroFourComponent projectData={projectData} /></div>
              {jsonDataArray.map((data, index) => (
                <React.Fragment key={`fragment-${index}`}>
                  <div className="page-break">
                    <GenDataComponent
                      project={projectData.name}
                      baseURL={projectData.url}
                      score={data.accessibilityScore}
                      path={data.webPath}
                      errorNum={data.errorArray.length}
                      passedNum={data.passedArray.length}
                      notApplNum={data.notApplArray.length}
                      manualNum={data.manualArray.length}
                      manualArray={data.manualArray}
                      scope={projectData.scope}
                      uploadDate={uploadDate}
                    />
                  </div>
                  <div className="page-break">
                    <CardsDataComponent
                      status="PASSED"
                      project={projectData.name}
                      baseURL={projectData.url}
                      path={data.webPath}
                      errorArray={data.errorArray}
                      passedArray={data.passedArray}
                      notApplArray={data.notApplArray}
                      scope={projectData.scope}
                    />
                  </div>
                  <div className="page-break">
                    <CardsDataComponent
                      status="ERROR"
                      project={projectData.name}
                      baseURL={projectData.url}
                      path={data.webPath}
                      errorArray={data.errorArray}
                      passedArray={data.passedArray}
                      notApplArray={data.notApplArray}
                      scope={projectData.scope}
                    />
                  </div>
                  <div className="page-break">
                    <CardsDataComponent
                      status="NOT"
                      project={projectData.name}
                      baseURL={projectData.url}
                      path={data.webPath}
                      errorArray={data.errorArray}
                      passedArray={data.passedArray}
                      notApplArray={data.notApplArray}
                      scope={projectData.scope}
                    />
                  </div>
                </React.Fragment>
              ))}
              <div className="page-break"><EndComponent /></div>
          </div>
          </IframeComponent>
        </div>
      )}
    </div>
  );
};

export default ProjectDetails;