import React from 'react';
import { useForm } from 'react-hook-form';

const ConfigForm = ({ onSubmit, isDisabled }) => {
  const { register, handleSubmit } = useForm({
    defaultValues: {
      name: '',
      url: '',
      scope: ''
    }
  });

  return (
    <form onSubmit={handleSubmit((data) => {
      if (!data.name || !data.url || !data.scope) {
        alert('Please fill in all fields.');
        return;
      }
      onSubmit(data);
    })}>
      <input type="text" {...register('name')} placeholder="Name" required />
      <input type="url" {...register('url')} placeholder="URL" required />
      <input type="text" {...register('scope')} placeholder="Scope" required />
      <button type="submit" disabled={isDisabled}>Generate Report</button>
    </form>
  );
};

export default ConfigForm;
